import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FilterService } from 'src/app/services/filter.service';
import { ValueBtnComponent } from './value-btn.component';
import { RouterHandlerService } from 'src/app/services/router-handler.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { NewFilterService } from 'src/app/services/new-filter.service';
import { PatientValueGetterComponent } from '../../shared/patient-value-getter/patient-value-getter.component';

@Component({
  selector: 'app-basic-grid-list',
  templateUrl: './basic-grid-list.component.html',
  styleUrls: ['./basic-grid-list.component.scss']
})
export class BasicGridListComponent implements OnInit {
  static key = 100811;

  cardName: any;
  clientKey: any;
  moduleKey: any;
  moduleName:any;
  dashboardKey: any;
  decryptedParams:any;
  cardId: any;
  patientId: any;
  @Input("item") item: any = {};
  @Input("pageKey") pageKey: any;
  
  @ViewChild("fs") fs!: ElementRef;
  @ViewChild("gridList") gridList!: ElementRef;
  @ViewChild("dataEditGridDiv", { static: true }) dataEditGridDiv!: ElementRef;

  @Output("onSizeChange") onSizeChange = new EventEmitter<any>();
  
  @HostBinding("class.is-fullscreen") isFullscreen = false;
  
  isActive = false;
  reqSubcription: Subscription[] = [];
  rowData:any[]=[];
  loader = true;
  moment = moment;
  filterValue: any;
  private gridApi!: GridApi;
  gridColumnApi:any
  
  constructor(
    public filterService: FilterService, 
    private apiService: ApiService,
    private httpClient: HttpClient,
    private routerHandlerService: RouterHandlerService,
    private navigationService: NavigationService,
    private newFilterService: NewFilterService,
    private currency: CurrencyPipe,
    private route: ActivatedRoute,
  ) {
    this.reqSubcription.push(this.filterService.filterQuery.subscribe((q:any)=> {
      this.loader = true;
      this.loadApiData()
    }))
    this.route.queryParams.subscribe((params: any) => {
      let decryptedParams = this.navigationService.decryptData(params);
      this.clientKey = decryptedParams["cl_key"];
      this.moduleKey = decryptedParams["md_key"];
      this.dashboardKey = decryptedParams["ds_key"];
      this.patientId = decryptedParams["cardId"];
      this.cardId = decryptedParams["cardId"];
    });
  }
  @HostListener("fullscreenchange", ["$event"])
  @HostListener("webkitfullscreenchange", ["$event"])
  @HostListener("mozfullscreenchange", ["$event"])
  @HostListener("MSFullscreenChange", ["$event"])
  screenChange(event:any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      // this.isFullscreen = false
    }
  }
  closeFullscreen() {
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }
  public columnDefs: ColDef[] = [];
  public detailRowHeight = 50;

  public defaultColDef: ColDef = {
    flex: 1,
    initialWidth: 200,
    wrapHeaderText: true,
    wrapText:true,
    autoHeight:true,
    autoHeaderHeight: true,
    headerClass: 'my-dataEdit-class-basic',
    resizable: true,
    sortable: true,
    suppressMenu:true,
  };
  public themeClass: string = "ag-theme-quartz";
  public paginationPageSizeSelector: number[] | boolean = [10, 20, 50, 100];

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api; 
    this.gridColumnApi= params.columnApi;
    
  }
  autoSizeAll(skipHeader: boolean) {
    const allColumnIds: string[] = this.gridColumnApi
      .getColumns()
      .map((column:any) => column.getId());
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
  ngOnInit(): void {
    const observer = new ResizeObserver((e: any) => {
      this.onSizeChange.emit(e[0].contentRect.height);
    });
    observer.observe(this.dataEditGridDiv.nativeElement);
    this.route.params.subscribe((p: any) => {
      this.moduleName = p['module'] || '';
      this.cardName = p['cardName'] || '';

      this.route.queryParams.subscribe((params: any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      });
    });

    
    this.loadConfiguration();
    if(this.item?.config?.['no_filter_execution']?.value=='true'){
      this.loadApiData()
    }

  }
 // Quick Filter for Type to Search Filter
 onQuickFilterChanged(event: any) {
  this.filterValue = event;
  this.gridApi.setQuickFilter(this.filterValue);
}
  loadConfiguration() {
debugger
    this.columnDefs = []
    let cfg = this.item.config;

    // if(cfg?.selected_column)return
    // console.log(cfg.selected_column)

    cfg?.selected_column?.forEach((res: any) => {
      res.property_CB.forEach((cb: any) => {
        if (cb.name == "Add Column") {
          if (cb.value) {
            var coldef: any = {
              headerName: res.api_resp_column_display_nm,
              field: res.api_resp_column_nm,
              cellClass: 'cell-wrap-text-basic-grid',
              autoHeight: true,
              menuTabs: [],
              minWidth: 150,
              refData: res
            };
            if (res.api_resp_column_nm == "du_patient_id_new") {
              coldef.minWidth = 150;
            }
            if (res.isAlignment && res.isTextColorCondition) {
              coldef["cellStyle"] = {
                color: res.isTextColor,
                textAlign: res.isAlignment.toLowerCase(),
                display: 'inline-grid'
              };
            } else if (res.isAlignment && !res.isTextColorCondition) {
              coldef["cellStyle"] = {
                textAlign: res.isAlignment.toLowerCase(),
                display: 'inline-grid'
              };
            } else if (res.isTextColorCondition) {
              coldef["cellStyle"] = { color: res.isTextColor };
            }

            // Data Types and Prefix and suffiz;
            let isPrefix = res.isPrefix || "";
            let isSuffix = res.issuffix || "";

            // debugger
            if (res.isDataType == "date") {
              debugger
               coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? isPrefix + "" + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + "" + isSuffix : "";
               coldef["valueGetter"] = (params: any) => params.data?.[res.api_resp_column_nm] ? isPrefix + " " + moment(params.data[res.api_resp_column_nm]).format("MM/DD/YYYY") + " " + isSuffix : "";
            } else if (res.isDataType == "integer") {
              if(res.isFormats=='Currency') {
                coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? this.currency.transform(params.value) + "" + isSuffix : "";
              } else if(res.isFormats=='Percentage') {
                coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? isPrefix + "" + params.value  + "" + isSuffix : params.value==0 ? isPrefix + "" +"0"+ "" + isSuffix:"";
              } else {
                coldef["valueFormatter"] = (params: any) => params.data[res.api_resp_column_nm] ? isPrefix + "" + this.numbedPipe(params.value) + "" + isSuffix : "";
              }
            } else {
              // String for Init cap and upper and lower
              if (res.isDataType == "string") {
                if (res.isTextCase == "from_db") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value + "" + isSuffix : "";
                } else if (res.isTextCase == "init") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toLowerCase().split(" ").map((s: any) => s.charAt(0).toUpperCase() + s.substring(1)).join(" ") + "" + isSuffix : "";
                } else if (res.isTextCase == "cap") {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toUpperCase() + "" + isSuffix : "";
                } else {
                  coldef["valueFormatter"] = (params: any) => params.value ? isPrefix + "" + params.value.toLowerCase() + "" + isSuffix : "";
                }
              }
            }
 // Conditional Formatter
 if (res.conditional_format == true || res.isActionIcon == true) {
  coldef["cellRenderer"] = PatientValueGetterComponent;
  coldef["cellRendererParams"] = (params: any) => {
    let selectedData;
    let selectedStyles;
    let columnClickData;
    if (res.conditional_formatting_value) {
      let abData = res.conditional_formatting_value.filter(
        (flr: any) => flr.isSelect == true
      );
      selectedData = abData;
    }

    if (res.isActionColumn == true) {
      if (res.isSelectionTypeColumn == "Column Click") {
        columnClickData = {
          api_key: res.isSelectionColumnAPI,
          params: res.isSelectionColumnParameter,
        };
      }
    }

    // icon for Actions
    if (res.isActionIcon == true) {
      selectedStyles = {
        icon: res.isActionIconName,
        color: res.isActionIconColor,
        position: res.isActionPosition,
        extraText: {
          prefix: res.isPrefix,
          suffix: res.issuffix,
        },
      };
      if (res.isEventsInIcon == true) {
        let iconsClick = {
          iconType: res.isSelectionTypeIcon,
          iconApi: res.isSelectionIconAPI,
          iconPara: res.isSelectionIconParameter,
        };
        Object.assign(selectedStyles, {
          iconsClickEvent: iconsClick,
        });
      }
    }
    return (
      this.cellRendererValueGetter(
        selectedData,
        params.data[res.condition_reference_column],
        params.value,
        selectedStyles
      ) || "" + params.value
    );
  };
}
            if(res.isConditionalFormat) {
              if(res.isConditionalTextColor=="backgroundColor") {
              coldef["cellStyle"] = (params: any) => {
                return {
                  backgroundColor: this.getDistinctValueColor(params.value,0.3),
                  color: "#000", // Ensure text is readable (adjust as needed)
                  // fontWeight: "bold"
                }
              }}else {
                coldef['cellStyle'] = (param:any) => {
                  return {
                    color: this.getDataColor(res.conditionalFormat, param)
                  };
                }
              }
            }

            this.columnDefs.push(coldef);
          }
        }
      });
    });
    console.log(this.columnDefs);
    
  }
  onCellClicked(event: any) {
    debugger
    if(event?.colDef?.refData?.isLinkCol) {

      // Storing for Patient Card if there!
      let resData = {
        patient_case_status: event.data.case_status,
        patient_reason_status: event.data.status_reason,
        patient_id: event.data.patient_id,
      };
      this.newFilterService.setPatientStatusData(resData);
      this.newFilterService.setPatientStatusBaseQuery(this.filterService.baseQuery);

      // Set Navigation
      let conf0 = event?.colDef?.refData
      let nav = {
        dbJSON : conf0.dbJSON,
        s3JSON : conf0.s3JSON,
        pageName : conf0?.pageName,
        isDashboardLevel : conf0.isDashboardLevel
      }

      // Prescriber Name Header
      if(nav.pageName?.toString().replace(/\s+/g, '').toLowerCase() == 'prescribercard') {
        // Prescriber Name
        let prName = 'prescriber_nm'
        if(event.data[prName]) {
          sessionStorage.setItem("navbar_prName", event.data[prName]);
        }
      } 

      
      this.routerHandlerService.navDetailInfo(nav); 
      this.routerHandlerService.storingPayloadAndFilterForGrid(conf0, event.data, this.cardName);

      let query:any = Object.assign({}, this.decryptedParams, {cardId : event.data[conf0.selectNavigatePart]}, {tab_id: 0}, {page: 'list'});
      let queryMaped = this.navigationService.movePropertyToFirst(query, 'tab_id');
      this.navigationService.routerNavigate(`dashboard/${this.moduleName}/${nav.pageName}`, queryMaped);
    }
  }
 colorList =["#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC",
  "#e74c3c", "#3498db", "#2ecc71", "#f1c40f", "#9b59b6",
  "#1abc9c", "#34495e", "#e67e22", "#16a085", "#d35400",
  "#2980b9", "#8e44ad", "#c0392b", "#27ae60", "#f39c12",
  "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71", "#f1c40f",
  "#9b59b6", "#1abc9c", "#34495e", "#e67e22", "#16a085",
  "#d35400", "#2980b9", "#8e44ad", "#c0392b", "#27ae60",
  "#f39c12", "#7f8c8d", "#e74c3c", "#3498db", "#2ecc71",
  "#f1c40f", "#9b59b6", "#1abc9c", "#34495e", "#e67e22",
  "#16a085", "#d35400", "#2980b9", "#8e44ad", "#c0392b",
  "#27ae60", "#f39c12", "#7f8c8d",
]
//  ["#F29F93", "#95D89C", "#92E9F5", "#FFD79C", "#8AB2EE", "#BBA4FF", "#BCE8A2", "#E581ED", "#FC8CAD", "#CF8080", "#F4E69B", "#EEB185", "#DDE89F", "#89DED4", "#C582F9", "#ECA3B5", "#96D6DA", "#F1B3E0", "#8CCDE9", "#8399E5",'#7181b4','#82a4e1','#8a9dd5','#9db5f4','#9f8dc8','#a47fa7','#a8d2c7','#b5d3d6','#c3e69b','#cda6e1','#cda4e3','#d7f4f8','#dcb9f1','#dcc9e3','#aadcbf','#e08685','#ec9a97','#ecd2ad','#f1f0c5','#f3c7af','#f4c9f0','#f8d4be','#f8c7e3','#fdd6d7','#fddbcb','#f7e2d8','#ffadab','#ffd3aa',...this.lightColor()]
      getRandomColor(count: any) {
        let shades: any = [];
        for (let i = 0; i < count; i++) {
          var length = 6;
          var chars = '0123456789ABCDEF';
          var hex = '#';
          while (length--) hex += chars[(Math.random() * 16) | 0];
          const shade = hex;
          shades.push(shade);
        }
        return shades;
      }
      lightColor() {
        let extracolor:any=this.getRandomColor(500)
        let lightColor:any=[]
        extracolor.forEach((darkColor:any)=>{
        const val = this.lightenHexColor(darkColor, 60);
        lightColor.push(val)
        })
      return lightColor
      }  lightenHexColor(hexColor:any, percent:any) {
        // Parse the hex color to RGB values
        const rgbColor = this.hexToRgb(hexColor);

        // Calculate the lightened RGB values
        const lightenedColor = rgbColor.map((value:any) => Math.min(Math.round(value + value * percent / 100), 255));

        // Convert the lightened RGB values back to hex
        const lightenedHexColor = this.rgbToHex(lightenedColor[0], lightenedColor[1], lightenedColor[2]);

        return lightenedHexColor;
      }

      hexToRgb(hex:any){
        // Remove the hash if it exists
        hex = hex.replace(/^#/, '');

        // Parse the hex color to RGB values
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return [r, g, b];
      }

      rgbToHex(r:any, g:any, b:any) {
        const toHex = (value:any)=>{
          const hex = value.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      }

  valueColorMap:any = new Map<string, string>(); // Store assigned colors for values
   colorIndex = 0; // Keep track of the current color index
  
  getDistinctValueColor(value: string, opacity = 0.1): string {
    if(!value){return'#fff'}
    if (!this.valueColorMap.has(value)) {
      const hexColor = this.colorList[this.colorIndex % this.colorList.length]; // Pick color
      this.valueColorMap.set(value, this.hexToRGBA(hexColor, opacity)); // Convert to RGBA with opacity
      this.colorIndex++;
    }
    return this.valueColorMap.get(value) as string;
  }
  hexToRGBA(hex: string, opacity: number): string {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');
  
    // Convert to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`; // Apply opacity
  }
  getDataColor(rangeValue: any, param: any) {
    if (!rangeValue) return null;

    for (let idx in rangeValue) {
      let r = rangeValue[idx];
      
      // >3000
      if(r.from_value == '>') {
        return r.color
      }
      
      // 0 - 10
      if ((parseInt(r.from_value) <= param.value || parseInt(r.from_value) <= param.value.value) && (param.value.value <= parseInt(r.to_value) || param.value <= parseInt(r.to_value))) {
        return r.color;
      }
    }
    return null;
  }

  numbedPipe(value: any) {
    return this.currency.transform(value, "", "", "1.0-2");
    //  return   this.numberpipe.transform(value)
  }

  loadApiData() {
    debugger
    this.rowData=[]
    const cfg = this.item.config
    let api = cfg['list_of_api'].api_key
    let obj:any
    if(cfg?.['no_filter_execution']?.value=='true'){
    this.filterService.baseQuery = this.newFilterService.getPatientStatusBaseQuery()
    // patient status and patient reason we need to know, when navigate to Patient Card
     obj = {
      api_key: this.item.config['list_of_api'].api_key,
      report_typ: "D",
      patient_id: this.cardId,
      client_key: this.clientKey || "",
    }}else{
      obj = {"api_key" : api}
    }

    if(api) {
      if(this.filterService.isEmptyObject(this.filterService.baseQuery) == false) {
        // API Payload
        // let obj:any = {"api_key" : api}
        let query = Object.assign({}, this.filterService.baseQuery, obj)
        query["report_typ"] = 'D';
  
        this.httpClient.post(`${this.apiService.apiBaseUrl}/${this.apiService.executionUrl}`, query).subscribe((res:any)=> {
          if(res.length>0) 
            this.rowData=[]
            this.rowData = res;
          setTimeout(() => {
            
            this.autoSizeAll(false);
          }, 0);
          this.loader=false;
        },(err:any)=>{
          console.log('basic grid list '+ api, err)
          this.loader=false;
        });
      }
    }

  //   this.httpClient.get(`assets/json/missing-msa.json`).subscribe((res:any)=> {
  //     if(res.length>0) 
  //       this.rowData = res;
  //     this.loader=false;
  //   },(err:any)=>{
  //     this.loader=false;
  //   });
  }

  exportXls(event:any) {
    if(event) {
      this.gridApi.exportDataAsExcel({
        fileName: this.item.config['report_heading'] || 'List Data', // Set your desired file name here
      });
    }
  }
  // Mat Icon Dynamically set with cell Renderer Value Getter
  cellRendererValueGetter(
    rangeValue: any,
    param: any,
    value: any,
    selectedStyles: any
  ) {

    if (!selectedStyles) {
      if (!rangeValue) return null;
    }
    if (rangeValue && !selectedStyles) {
      for (let idx in rangeValue) {
        let r = rangeValue[idx];
        if (r.col_value == param) {
          let icon: any = r.icon;
          let color: any = r.color;
          return { icon, value, color, selectedStyles };
        }
      }
    } else if (rangeValue && selectedStyles) {
      let fnd: any = rangeValue.find((res: any) => res.col_value == param)
      if (fnd) {
        for (let idx in rangeValue) {
          let r = rangeValue[idx];
          let icon = '';
          let color = '';

          if (r.col_value.toLowerCase() == param.toLowerCase()) {
            icon = r.icon;
            color = r.color;
            return { icon, value, color, selectedStyles };
          }
        }
      } else {
        return { selectedStyles };
      }
    } else if (selectedStyles) {
      return { value, selectedStyles };
    }
  }
  screenChanges(event:any) {
    this.isActive = event
    this.isFullscreen = event
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
