<div class="widget-container" #dataEditGridDiv>
    <!-- Headings -->
    <main >
        <article #fs fullscreen-able [class.is-active]="isActive" [class.isFulScreen]="isActive" style="background-color: #fff;">
            <div class="mb-1">
                <icon-chart 
                    [headerConfig]="item.config?.widget_header_config"
                    [rowDataLength]="this.rowData" 
                    [data]="this.rowData" 
                    [pageKey]="pageKey" 
                    [heading]="this.item.config['report_heading']"
                    [chartName]="'GridList'" 
                    [expand]="fs" 
                    [fullscreen]="isFullscreen"
                    [item]="item"
                    (screenChanges)="screenChanges($event)"
                    (typeToSearch)="onQuickFilterChanged($event)"
                    (exportXls)="exportXls($event)"
                ></icon-chart>

            </div>

            <div *ngIf="loader" style="width: 100%; height: 200px;">
                <loading></loading>
            </div>

            <ag-grid-angular
                style="width: 100%; "
                class="ag-theme-alpine editDataGrid"
                [class.fullScreenEdits]="isFullscreen"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [rowData]="rowData"
                [class]="themeClass"
                [suppressDragLeaveHidesColumns]="true"
                [suppressContextMenu]="false"
                [rowHeight]="50"
                [pagination]="true"
                [paginationPageSize]="10"
                (cellClicked)="onCellClicked($event)"
                [paginationPageSizeSelector]="paginationPageSizeSelector"
                (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </article>
    </main>
</div>