import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import* as _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-cluster-stacked-bar-chart',  
  templateUrl: './cluster-stacked-bar-chart.component.html',
  styleUrls: ['./cluster-stacked-bar-chart.component.scss']
})
export class ClusterStackedBarChartComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('stackAreaBarContainer', { static: true }) stackAreaBarContainer!: ElementRef;
  isVisible:boolean = true;
  @Input('props')  props:any = {};
  @Input('chartData')  chartData:any = [];
  @Input('childChartData')  childChartData:any = [];
  @Input('isFullscreen')  isFullscreen:any = false;
  @Input('config')  config:any;
  smallChartConfig:any
  smallBarChartData:any 
  loader:boolean=false
  divId = "stackedAreaBar"; 
  tooltip = "d3ClusterStackedBarTooltip";
  currentVisibleData: any = [];
  moment = moment;
  isTooltip = false;
  toolTipValue:any = {};
  
  dataTurn: any
  height: any = 0
  mytooltipData:any;
  arrowVisible:boolean=false;
  constructor(private currency: CurrencyPipe) { }

  ngOnInit(): void {
     this.divId = this.divId + this?.['config']?.list_of_api;
      this.tooltip = this.tooltip + this?.['config']?.list_of_api;
    // this.initiateCharts();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['chartData']?.currentValue != changes?.['chartData']?.previousValue) {
      // this.divId = this.divId + changes['config'].currentValue?.list_of_api;
      // this.tooltip = this.tooltip + changes['config'].currentValue?.list_of_api;
      // setTimeout(() => {
        this.backarrow()
        // this.plotChart();
      // }, 200); 
    }
    
    if (!changes?.['isFullscreen']?.firstChange && changes?.['isFullscreen']?.currentValue != changes?.['isFullscreen']?.previousValue && this.isVisible ) {
      this.loader=true
      setTimeout(() => {
            d3.select('#svg_' + this.divId).remove();
            this.initiateCharts();
            this.plotChart();
            this.loader=false
          }, 100);
     
    }
  }

  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }  
  detailChart(myData:any,staticColor:any){
    debugger
    this.loader=true
    const data:any = [];
    let loopData:any = this.props.staticColor ? staticColor: this.props.barFill
    for(let obj in loopData) {
      let object = {
        name: obj,
        color: staticColor[obj],
        key: obj,
        value: myData[obj],
        date:myData['period'],
        period:myData['date']
      }
      if( object.value != undefined){
        data.push(object)}
    }
    this.smallBarChartData = this.childChartData.filter((item: any) =>
     item[this.props.subGroupName]=== data[0].key &&
      moment(item.month_end_date).isSame(moment(data[0].date), 'day')
  );
  this.smallBarChartData = _.orderBy( this.smallBarChartData, ['no_of_patients'], ['desc']);
    this.arrowVisible =true
    this.isVisible=false
    data['status']=  (this.config.list_of_api==100706)? this.childChartData[0].patient_status :undefined
    this.smallChartConfig= data
    this.loader=false
  // console.log(data);
  
  }
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any,staticColor:any): void {
    // console.log(myData);
    // debugger
    const data:any = [];
  let loopData:any = this.props.staticColor ? staticColor: this.props.barFill
    for(let obj in loopData) {
      let object = {
        name: obj,
        color:staticColor[obj],// this.props.barFill[obj],
        key: obj,
        value: myData[obj]
      }
     if( object.value != undefined){
      data.push(object)}
    }

    myData['data'] = data;
    
    this.mytooltipData = myData
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    // if (this.isFullscreen == true) {
      if (this.height < 200) {
        d3.select(`#${this.tooltip}`)
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('bottom', (this.height - 60) + 'px')
          .style('top', 'unset')
      }
      else if (this.height > 200) {

        d3.select(`#${this.tooltip}`)
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('top', (myY + 30) + 'px')
          .style('bottom', 'unset')
      }

      if (this.dataTurn < 350) {
        d3.select(`#${this.tooltip}`)
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 350) {

        d3.select(`#${this.tooltip}`)
          .style('visibility', 'visible')
          .style('position', 'absolute')
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }
      this.isTooltip = true

    // } 
    // else {
    //   if (this.height < 200) {
    //     d3.select(`#${this.tooltip}`)
    //       .style('visibility', 'visible')
    //       .style('position', 'absolute')
    //       .style('bottom', (this.height +  60) + 'px')
    //       .style('top', 'unset')
    //   }
    //   else if (this.height > 200) {

    //     d3.select(`#${this.tooltip}`)
    //       .style('visibility', 'visible')
    //       .style('position', 'absolute')
    //       .style('top', (myY + 60) + 'px')
    //       .style('bottom', 'unset')
    //   }

    //   if (this.dataTurn <350) {
    //     d3.select(`#${this.tooltip}`)
    //       .style('visibility', 'visible')
    //       .style('position', 'absolute')
    //       .style('right', (this.dataTurn + 166) + 'px')
    //       .style('left', 'unset')
    //   }
    //   else if (this.dataTurn >350) {

    //     d3.select(`#${this.tooltip}`)
    //       .style('visibility', 'visible')
    //       .style('position', 'absolute')
    //       .style('left', (myX - 90) + 'px')
    //       .style('right', 'unset')
    //   }
    //   this.isTooltip = true
    // }
  }

  private hideTooltip(myType: any): void {
    d3.select(`#${this.tooltip}`)
        .style('visibility', 'hidden');
        this.isTooltip = false
  }

  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select(this.stackAreaBarContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);
    const defs = mySvg.append('defs');

    defs
      .append('clipPath')
      .attr('id', 'brushClip' + myClass)
      .append('rect')
      .attr('id', 'brushClipRect' + myClass);

    const filter = defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('width', 10)
      .attr('height', 24);
    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 1)
      .attr('result', 'blur');
    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('result', 'offsetBlur');
    filter
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', '#000000')
      .attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    mySvg
      .append('g')
      .attr('id', 'xAxis' + myClass)
      .attr('class', 'axis' + myClass);
    mySvg
      .append('g')
      .attr('id', 'yAxis' + myClass)
      .attr('class', 'axis' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
    mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg.append('g').attr('id', 'chartGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass);
    mySvg.append('g').attr('id', 'brushSelectionGroup' + myClass);
       // legend stuff
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    

    //tooltip groupss
    mySvg.append('line').attr('class', 'dotLine' + myClass);
    mySvg.append('g').attr('class', 'areaDotsGroup' + myClass);
    mySvg.append('g').attr('class', 'lineDotsGroup' + myClass);

    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg
      .append('line')
      .attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
      mySvg.append('g').attr('id', 'legendGroup' + myClass);
    mySvg
      .append('circle')
      .attr('class', 'legendCircleBar' + myClass + ' legendItem' + myClass);
    mySvg
      .append('circle')
      .attr('class', 'legendCircleLine' + myClass + ' legendItem' + myClass);
    mySvg
      .append('text')
      .attr('class', 'legendLabelLine' + myClass + ' legendItem' + myClass);
    mySvg
      .append('text')
      .attr('class', 'legendLabelBar' + myClass + ' legendItem' + myClass);

    // legend stuff
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
  }

  plotChart(): void {
    const myChart = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width  = this.isFullscreen==false ? mySvg.node().getBoundingClientRect().width :  window.outerWidth 
    
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 60;
    } else {
      this.props.chartHeight = 500
    }
    
    const height = this.props.chartHeight;
    mySvg.attr('height', height);

    const timeConverter: any = {
      D: d3.timeDay,
      W: d3.timeWeek,
      M: d3.timeMonth,
      Q: d3.timeMonth,
      Y: d3.timeYear,
      PCQ: d3.timeMonth,
    };
    
    const tickFormat: any = {
      D: '%d %b %Y',
      W: '%d %b %Y',
      M: '%b %Y',
      Q: '%b %Y',
      Y: '%Y',
      PCQ: '%b, Q%q %Y',
    };
    
    const timePeriod = this.props.dateFormat;

    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
    let chartData: any = this.chartData;
    
    // @ts-ignore
    chartData.map((m: any) => (m.date = new Date(m[myChart.props.xAxisVar])));
    const subGroup: any = Array.from(new Set(chartData.map((d: any) => d[myChart.props.subGroupName]))); // Convert Set to Array

    // Create Source Set
    // build source set from group var
    let sourceSet = new Set();
    chartData.forEach((d: any) => sourceSet.add(d[myChart.props.stackVar]));
    // chartData.forEach((d: any) => sourceSet.add(d[myChart.props.stackAreaVar]));
    
    let sourceSets = Array.from(sourceSet);

    // define colours now we have unique list of groups
    const barColors:any = {}, barColorsLight:any = {};
    if(myChart.props.staticColor){
    Array.from(sourceSet).forEach((d: any, i: number) => {
      barColors[d] = myChart.props.colors[i];
      // barColorsLight[d] = myChart.props.barFill[i];
    });}else{
    sourceSet.forEach((d: any, i: any) => {
      barColors[d] = myChart.props.barFill[i];
      // barColorsLight[d] = myChart.props.barFill[i]
    })}

    //////////////////
    let dateGroup = Array.from(d3.group(chartData, (g: any) => g.date));
    dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));
    
    const dateGroupDates: any = []
    let BarChartData: any = [];
    let tooltipData: any = {};

    let barVars: any = new Set();
    let stackVars: any = new Set();
    let dateSet: any = new Set();
    
    myChart.chartData.forEach((d: any) => {
      barVars.add(d[myChart.props.xAxisVar]);
      dateSet.add(d[myChart.props.xAxisVar]);
      stackVars.add(d[myChart.props.stackVar]);
    });

    // sort and convert dateset
    // dateSet = Array.from(dateSet).map((m: any) => m = new Date(m));
    dateSet = Array.from(dateSet);
    dateSet = dateSet.sort((a: any, b: any) => d3.ascending(a, b));
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Bar Stacking
    // Create Bar
    let stackData: any = [];
    Array.from(barVars).forEach((d: any) => {
      const myData: any = { name: d };

      // Initialize tooltip data if not already initialized
      if (!tooltipData[String(d)]) {
        tooltipData[String(d)] = {};
      }

      tooltipData[String(d)].date = d;

      // Loop through stack variables to create stack data
      Array.from(stackVars).forEach((v: any) => {
        let myValue: any = 0;

        // Find the matching data point
        let fnd = myChart.chartData.find(
          (f: any) =>
            f[myChart.props.xAxisVar] === d && f[myChart.props.stackVar] === v
        );

        if (fnd) {
          myValue = fnd[myChart.props.stackValueVar] || 0;
        }
        myData[v] = myValue;
      });

      stackData.push(myData);

      // Tooltip for each subgroup
      Array.from(subGroup).forEach((s: any) => {
        // Initialize the subgroup if it doesn't exist in tooltipData
        if (!tooltipData[String(d)][s]) {
          tooltipData[String(d)][s] = {};
        }

        Array.from(stackVars).forEach((v: any) => {
          const fnd = myChart.chartData.find(
            (f: any) =>
              f[myChart.props.xAxisVar] === d &&
              f[myChart.props.stackVar] === v &&
              f[myChart.props.subGroupName] === s
          );

          if (fnd) {
            // Assign values to the tooltip data
            tooltipData[String(d)][s][v] =
              fnd[myChart.props.stackValueVar] || 0; // Y-axis value
            tooltipData[String(d)][s]["subGroup"] =
              fnd[myChart.props.subGroupName] || ''; // Subgroup name
            tooltipData[String(d)][s]["date"] = d || ''; // Date
          }
        });
      });
    });
     
    const stackBar:any = [...new Set(this.chartData.map((m: any) => m[myChart.props.stackVar]))]
    stackData = d3
      .stack()
      .keys(Array.from(stackBar))
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone)(stackData);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Area Stacking
    // debugger
    let stackVars_area: any = new Set();

    myChart.chartData.forEach((d: any) => {
      barVars.add(d[myChart.props.xAxisVar]);
      stackVars_area.add(d[myChart.props.stackAreaVar]);
    });
    
    let AstackData: any = [];
    // Loop through bar variables (X-axis groups)
    Array.from(barVars).forEach((d: any, i: any) => {
      const myData: any = { name: d };

      // Initialize tooltip data for this 'd' if not already initialized
      if (tooltipData[String(d)] === undefined) {
        tooltipData[String(d)] = {};
      }

      tooltipData[String(d)].date = d;

      // Loop through stack area variables to create data
      Array.from(stackVars_area).forEach((v: any) => {
        let myValue: any = 0;

        // Find matching data point for the given `xAxisVar` and `stackAreaVar`
        let fnd = this.chartData.find(
          (f: any) =>
            f[myChart.props.xAxisVar] === d && f[myChart.props.stackAreaVar] === v
        );

        if (fnd) {
          myValue = fnd[myChart.props.stackAreaValueVar] || 0;
        }
        myData[v] = myValue; // Store the value in `myData` for stacking
      });

      AstackData.push(myData);

      // Tooltip logic for each subgroup
      Array.from(subGroup).forEach((s: any) => {
        // Initialize subgroup in tooltip data if not already done
        if (!tooltipData[String(d)][s]) {
          tooltipData[String(d)][s] = {};
        }

        Array.from(stackVars_area).forEach((v: any) => {
          // Find the matching data point for tooltip data
          const fnd = this.chartData.find(
            (f: any) =>
              f[myChart.props.xAxisVar] === d &&
              f[myChart.props.stackAreaVar] === v &&
              f[myChart.props.subGroupName] === s
          );

          if (fnd) {
            // Assign values to tooltip data
            tooltipData[String(d)][s][v] =
              fnd[myChart.props.stackAreaValueVar] || 0; // Y-axis value
            tooltipData[String(d)][s]["subGroup"] =
              fnd[myChart.props.subGroupName] || ''; // Subgroup name
            tooltipData[String(d)][s]["date"] = d || ''; // Date
          }
        });
      });
    });

    // const stackArea:any = [...new Set(this.chartData.map((m: any) => m[myChart.props.stackAreaVar]))]
    // AstackData = d3
    //   .stack()
    //   .keys(Array.from(stackArea))
    //   .order(d3.stackOrderNone)
    //   .offset(d3.stackOffsetNone)(AstackData);


    // Define subgroups and unique kpi names for stacking
    const subgroups: any =  [...new Set(chartData.map((d: any) => d[myChart.props.subGroupName]))].slice(0, 10); // Extract unique subgroups
    const kpiNames: any = [...new Set(chartData.map((d: any) => d[myChart.props.stackVar]))]; // Extract unique KPI names

    // Group by `source_nm` and sum `kpi_value` for each group
      const BargroupedData =  d3.group(chartData, (d:any) => `${d[myChart.props.subGroupName]}-${d[myChart.props.xAxisVar]}`); 

      const StacKBarMax =  Array.from(BargroupedData, ([key, values]) => {
        const [source, period] = key.split('-'); // Split composite key into source and period
        return {
          source,
          period,
          totalValue: d3.sum(values, d => d[myChart.props.stackValueVar]),
        };
      });

  



    //area data
    const tickValues =
      myChart.props.d3AxisFormatting === true ? null : dateGroupDates;
   debugger
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    let yMax:any =0 //= d3.max(AstackData, (d: any) => d3.max(d, (m: any) => m[1]));
    // const yMaxBarStack:any = d3.max(stackData, (d: any) => d3.max(d, (m: any) => m[1]));
    // const yMaxBarStack:any = d3.max(chartData, (d: any) =>  d[myChart.props.stackValueVar])  // Get the maximum 'end' value from each stack
    const yMaxBarStack:any= d3.max(StacKBarMax, d => d.totalValue);
   
    if(yMaxBarStack>yMax) {yMax=yMaxBarStack}
    // let yMax:any = d3.max(chartData, (d: any) =>  d[myChart.props.stackValueVar])
console.log(BargroupedData,StacKBarMax,yMax);

    let xExtent: any = d3.extent(chartData, (d: any) => d.date);
    const xMax = d3.max(chartData, (d: any) => d.date);

    xExtent[0] = timeConverter[timePeriod].offset(xExtent[0], -1);
    xExtent[1] = timeConverter[timePeriod].offset(xExtent[1], 1);
   
    let margins = {
      left: 75,
      right: 40,
      top: 60,
      bottom: 10,
      brush: 52,
      mid: 60,
      sub: 5,
      axis: 40
    };
    if (String(this.props.axisRotation).includes('90')) {
      margins.mid += 15;
    }
    // subgroup height
    if(subgroups.length>1 && myChart.props.subgroups==true) {
      margins.sub = 55
    }else(
      margins.sub = 10
    )

    if (myChart.props.showBrush === false) {
      margins.brush = 0;
      margins.mid = 50;
      margins.bottom = 0;
    }
    //@ts-ignore
    let startingVisibleRows: any = parseInt((width - margins.left - margins.right) / (subgroups.length+1));
    startingVisibleRows = startingVisibleRows >  (chartData.length) ? chartData.length  : startingVisibleRows;

    const brushStartX = chartData.length === 0 ? 0 : (startingVisibleRows / chartData.length) * (width - margins.left - margins.right);
    const chartHeight = height - margins.top - margins.bottom - margins.brush - margins.mid - margins.sub;

    const brush: any = d3
      .brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
      //@ts-ignore
      .on('start brush end', !myChart.props.showBrush ? null : brushed);

    mySvg
      .select('#noDataMessage' + myClass)
      .attr('x', margins.left + (width - margins.left - margins.right) / 2)
      .attr('y', margins.top + (height - margins.top - margins.mid - margins.sub - margins.brush - margins.bottom) / 2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(chartData.length === 0 ? 'There is no data for this time period' : '');

    mySvg.select('.overlay').style('fill', 'transparent');

    mySvg
      .select('#brushClipRect' + myClass)
      .style('width', width - margins.left - margins.right)
      .style('height', height)
      .attr('transform', 'translate(' + margins.left + ',0)');

  
    const xDomain: any = Array.from(new Set(chartData.map((d: any) => d.date)));
    
  
  function roundToNearest(num:any) {
    // Determine the nearest power of 10 based on the number
    const magnitude = Math.pow(10, Math.floor(Math.log10(num)));
    // Round the number up to the next multiple of that magnitude
    return Math.ceil(num / magnitude) * magnitude;
  }

  yMax=roundToNearest(Number(yMax))
    const yScale = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([Number(height - margins.top - margins.bottom - margins.mid - margins.sub - margins.brush), 0]);

    const yScaleHeight = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([0, height - margins.top - margins.bottom])
   
    const yScaleBrush = d3
      .scaleLinear()
      .domain([0, Number(yMax)])
      .range([margins.brush, 0]);
      
   
    const xScaleAll: any = d3
      .scaleTime()
      .range([0, width - margins.left - margins.right])
      .domain(xExtent);
      
    const xScaleSelected: any = d3
      .scaleTime()
      .range([0, width - margins.left - margins.right])
      .domain(xExtent);


    ////////////////
      
    const extraYs: any = {};
    stackData.forEach((d: any, i: any) => {
     debugger
      const myKey = d.key;
      const isLast = i === stackData.length - 1;
      d.map((m: any) => (m.value = m[1] - m[0]));
      d.map((m: any) => (m.height = yScaleHeight(m.value)));
      d.forEach((s: any, index: any) => {
        
        let myExtraY = 0;
        if (s.height < 2 && s.value > 0) {
          myExtraY = (2 - s.height);
          s.height = 2;
          extraYs[index] =
            extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY;
        }
        let myFill = myChart.props.barFill[myKey] || 'red';
        if (myFill === undefined) {
          myFill = myChart.props.stackBarColors[i] || '#000';
          myChart.props.barFill[myKey] = myFill;
          myChart.props.barLabels[myKey] = myKey;
        }
       
        BarChartData.push({
          date: new Date(s['data'].name),
          start: s[0],
          value: s[1],
          height: s.height,
          fill: myFill,
          extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
          totalValue: s[1],
          borderRadius: isLast ? 2 : 0,
          groupIndex: index,
          stackIndex: i,
          errorType: myKey,
        });
      });
    });

    dateGroup.forEach((d: any, i: any) => {
      dateGroupDates.push(d[0]);
    });
  

  ///////////////////////////////////////////////  
    // AstackData.forEach((d: any, i: any) => {
    //   const myKey = d.key;
    //   const isLast = i === stackData.length - 1;
    //   d.map((m: any) => (m.value = m[1] - m[0]));
    //   d.map((m: any) => (m.height = yScaleHeight(m.value)));
    //   d.forEach((s: any, index: any) => {
       
    //     let myExtraY = 0;
    //     if (s.height < 2 && s.value > 0) {
    //       myExtraY = (2 - s.height);
    //       s.height = 2;
    //       extraYs[index] =
    //         extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY;
    //     }
    //     let myFill = myChart.props.barFill[myKey];
    //     if (myFill === undefined) {
    //       myFill = myChart.props.stackAreaColors[i] || '#000';
    //       myChart.props.barFill[myKey] = myFill;
    //       myChart.props.barLabels[myKey] = myKey;
    //     }
        
    //     // AreaChartData.push({
    //     //   date: new Date(s['data'].name),
    //     //   start: s[0],
    //     //   value: s[1],
    //     //   height: s.height,
    //     //   fill: myFill,
    //     //   extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
    //     //   totalValue: s[1],
    //     //   borderRadius: isLast ? 2 : 0,
    //     //   groupIndex: index,
    //     //   stackIndex: i,
    //     //   errorType: myKey,
    //     // });
    //   });
    // });
    // dateGroup.forEach((d: any, i: any) => {
    //   dateGroupDates.push(d[0]);
    // });

    // X Axis Label
    mySvg
      .select('#xAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('x', margins.left + (width - margins.left - margins.right) / 2)
      .attr('y', height - margins.bottom - margins.brush - 6)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.xAxisLabel);

    // Y Axis Label
    mySvg
      .select('#yAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('transform', 'translate(13,' + (margins.top + (height - margins.top - margins.bottom - margins.brush - margins.mid - margins.sub) / 2) + ') rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.yAxisLabel);

    mySvg
      .select('#xAxis' + myClass)
      .call(
        d3
          .axisBottom(xScaleSelected)
          .tickSizeOuter(0)
          .tickValues(tickValues)
          .ticks(tickCount)
          .tickFormat(xTickFormat)
      )
      .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');

    // Y Axis Scale
    mySvg
      .select('#yAxis' + myClass)
      .call(
        d3
          .axisLeft(yScale)
          .tickSizeOuter(0)
          .ticks(6)
          .tickFormat((d: any) => d3.format(myChart.props.yAxisFormatting)(d))
        )
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg
      .selectAll('.axis' + myClass + ' path')
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .style('stroke', '#E8EAEE');

    //////////////////////
    // onhover
    // this is the mouseover line, only visible at mouseover, setting key properties
    
    //   mySvg.select('.dotLine' + myClass)
    //   .attr('visibility', 'hidden')
    //   .attr('pointer-events', 'none')
    //   .attr('stroke', '#A0A0A0')
    //   .attr('fill', '#A0A0A0')
    //   .attr('stroke-width', 2)
    //   .attr('y1', 0)
    //   .attr('y2', height - margins.top - margins.bottom)
    //   .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');

    const chartGroupData = myChart.props.showBrush === false ? ['chart'] : ['brush', 'chart'];

    const areaBrush: any =  d3.area()
      .curve(d3.curveLinear)
      .x((d: any) => xScaleAll(new Date(d.data.name)))
      .y0((d: any) =>  yScaleBrush(d[1]))
      .y1( (d: any) =>  yScaleBrush(d[0]))
      
    const area: any = d3.area()
      .curve(d3.curveLinear)
      .x((d: any) => xScaleSelected(new Date(d.data.name)))
      .y0((d: any) =>  yScale(d[1]))
      .y1( (d: any) =>  yScale(d[0]))

    mySvg
      .select('#brushGroup' + myClass)
      .attr('transform', 'translate('+ margins.left + ',' + (margins.top + chartHeight + margins.mid + margins.sub) +')')
      .call(brush)
      .call(brush.move, [brushStartX, width - margins.left - margins.right]);

    // Remove the brush from the SVG If properties is false 
    if (myChart.props.showBrush === false) {
      mySvg
        .select('#brushGroup' + myClass)
        .call(brush.move, null)
        .selectAll('*')
        .remove();
    }

    // brush handle lines
    mySvg
      .selectAll('.handleLines' + myClass)
      .attr('visibility', chartData.length === 0 || myChart.props.showBrush === false ? 'hidden' : 'visible')
      .attr('y1', margins.top + chartHeight + margins.mid + margins.sub + (margins.brush - 12) / 2)
      .attr('y2', margins.top + chartHeight + margins.mid + margins.sub + 12 + (margins.brush - 12) / 2)
      .style('stroke', '#8A98AB')
      .style('stroke-width', '1')
      .attr('transform', 'translate(' + margins.left + ',0)');
      
////////////////////////////////////////////////////////////////////////////////////////////
    // legend functionality with buttons
    // same on combo/line/area line
    // see line chart for comments/explanations
    let currentLegendSet: any = [];
    currentLegendSet = currentLegendSet.concat(sourceSets);
    let currentLegendItems =  Object.keys(myChart.props.barFill) //JSON.parse(JSON.stringify(currentLegendSet));
    console.log(currentLegendItems)
    let legendIndexLeft = 0;
    let legendIndexRight = currentLegendItems.length;
    const legendWidth = d3.sum(currentLegendItems, (s: any) => measureWidth(s?.toUpperCase(), 12)  + 40)
    mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
    if (legendWidth < (width - 60)){
      mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
    } else {
      currentLegendItems = [];
      let currentWidth = 0;
      currentLegendSet.forEach((d: any, i: any) => {
        currentWidth += (measureWidth(d?.toUpperCase(), 12) + 40);
        if (currentWidth < (width - 60)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }
    
    // more legend - see line
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 21)
      .attr('y', 35 + 5.5)
      .text('<');
    // more legend - see line
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft += 1;
        legendIndexRight += 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (currentLegendSet.length - 1)){
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 21)
      .attr('y', 35 + 5.5)
      .text('>');

    drawLegend(BarChartData.length === 0 ? [] : currentLegendItems)
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    drawChart();

    mySvg
      .selectAll('#brushGroup' + myClass)
      .selectAll('.selection')
      .attr('fill', '#A0A0A0')
      .attr('fill-opacity', '0.2')
      .style('stroke', '#E8EAEE')
      .style('stroke-width', '1');

    function drawLegend(currentLegendItems: any): void {
      // draw legend, similar in all cases, see area line for full explanation
      const legendGroup = mySvg
        .select('#legendGroup' + myClass)
        .selectAll('.legendGroup' + myClass)
        .data(  _.compact(currentLegendItems))
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d?.replace(/ /g, '') + myClass)
        .attr('cy', 35)
        .attr('fill', (d: any) => myChart.props.barFill[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + d?.replace(/ /g, '') + myClass)
        .attr('y', 35 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) => d?.toUpperCase().replace(/_/g, ' '));

      let legendX = 30;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject).attr('x', legendX);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        legendX += (40 + measureWidth(d?.toUpperCase(), 12));
      });

      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }
  
    function drawChart(): void {

      // Group data by 'date'
      const groupedData = d3.group(chartData, (d: any) => d.date);

      
      
      const maxBarWidth = 14;  // Maximum width for each bar
      const barPadding = 0.4;  // Padding between bars (20% of the bar width)

      // Calculate dynamic range for xScaleSubgroup
      const mainGroupWidth = groupedData.size > 1 ? xScaleSelected(new Date([...groupedData.keys()][1])) - xScaleSelected(new Date([...groupedData.keys()][0])):xScaleSelected(new Date([...groupedData.keys()][0]));

      const numberOfSubgroups = subgroups.length;
      const totalPadding = (mainGroupWidth * 1) * 0.2; // Set 20% of the group width as padding
      const availableWidth =  (mainGroupWidth * 1) - totalPadding;

      // Adjust bar width and include space between bars
      const barWidth = Math.min(availableWidth / (numberOfSubgroups * (1 + barPadding)), maxBarWidth);

      mySvg
        .selectAll('#xAxis' + myClass + ' line')
        .attr('y1', '0')
        .attr('y2', -(height - margins.top - margins.bottom - margins.brush - margins.mid - margins.sub))
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);

      mySvg
        .selectAll('.axis' + myClass + ' text')
        //@ts-ignore
        .style('font-weight', myChart.props.axisFontWeight)
        .style('font-family', 'Poppins')
        .attr('cursor', 'default')
        //@ts-ignore
        .style('font-size', myChart.props.axisFontSize);

      mySvg
        .selectAll('#xAxis' + myClass + ' text')
        .style('text-anchor', 'middle')
        .attr('cursor', 'default')
        .attr('dy', margins.sub)
        .attr('transform', '');
      
      // debugger
      const xBandwidth = dateGroupDates.length > 1 ? xScaleSelected(dateGroupDates[1]) - xScaleSelected(dateGroupDates[0]) : 0;
      const maxTextWidth = getMaxTextWidth();
      
      if (maxTextWidth > xBandwidth - 5) {
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;

        mySvg
          .selectAll('#xAxis' + myClass + ' text')
          .style('text-anchor', myChart.props.d3AxisFormatting === true ? 'middle' : 'end')
          .style('font-size', myFontSize)
          .attr('dx', 0)
          //@ts-ignore
          .attr('dy', '0.71em') // Keep dy minimal for better alignment
          .attr('transform', function () {
            // const yPosition = additionalSpace + 10; // Adjust to move text without moving axis
            return `translate(0, ${margins.sub-10}) rotate(${myChart.props.d3AxisFormatting === true ? 0 : -45})`;
          });
      }

      function getMaxTextWidth() {
        let maxTextWidth = 0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function () {
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width);
        });
        return maxTextWidth;
      }

      mySvg
        .selectAll('#yAxis' + myClass + ' line')
        .attr('x1', '0')
        .attr('x2', width - margins.right - margins.left)
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);


      if (myChart.props.showBrush === true) {
        const handleEastX = +mySvg
          .select('#brushGroup' + myClass)
          .select('.handle--e')
          .attr('x');

        const handleWestX = +mySvg
          .select('#brushGroup' + myClass)
          .select('.handle--w')
          .attr('x');

        mySvg
          .select('.handleLeftLine1' + myClass)
          .attr('x1', handleEastX + 3)
          .attr('x2', handleEastX + 3);

        mySvg
          .select('.handleLeftLine2' + myClass)
          .attr('x1', handleEastX + 7)
          .attr('x2', handleEastX + 7);

        mySvg
          .select('.handleRightLine1' + myClass)
          .attr('x1', handleWestX + 3)
          .attr('x2', handleWestX + 3);

        mySvg
          .select('.handleRightLine2' + myClass)
          .attr('x1', handleWestX + 7)
          .attr('x2', handleWestX + 7);

        mySvg
          .select('#brushGroup' + myClass)
          .selectAll('.handle')
          .attr('fill', 'white')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('y', (margins.brush - 24) / 2)
          .attr('height', 24)
          .style('filter', 'url(#drop-shadow)');
      }

      mySvg
        .selectAll('.selection')
        .attr('visibility', BarChartData.length === 0 ? 'hidden' : 'visible');

      
      myChart.currentVisibleData = [chartData, xScaleSelected.domain()];
      
      ////////////////////////////bar creation end start
      const chartGroup = mySvg
          .select('#chartGroup' + myClass)
          .selectAll('.chartGroup' + myClass)
          .data(chartGroupData)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'chartGroup' + myClass);
            enter.append('g').attr('class', (d : any) => d === 'brush' ? 'averageLine' + d : 'averageLine' + d);
            enter.append('g').attr('class', (d : any) => d === 'brush' ? 'barsGroup' + d : 'barsGroup' + d);
            return enter;
          });

      chartGroup
        .attr('clip-path', (d: any) => d === 'brush' ? '' : 'url(#brushClip' + myClass + ')');
      
      // chartGroup
      //       .select('.averageLinechart')
      //       .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? margins.mid + margins.sub + chartHeight : 0) + ')');
      
      // for brush line chart
      chartGroup
        .select('.averageLinebrush')
        .attr('transform', (d: any) => 'translate(0, 0)');
      
      // const areaGroup = chartGroup
      //   .select('.averageLinechart')
      //   .selectAll('.areasGroupGroup')
      //   .data(AstackData)
      //   .join((group: any) => {
      //     const enter = group.append('g').attr('class', 'areasGroupGroup');
      //       enter.append('path').attr('class', 'areaGroupItems averageDot');
      //     return enter;   
      //   })
      
      // for brush
      // const areabrush = chartGroup
      //   .select('.averageLinebrush')
      //   .selectAll('.areasGroupGroup')
      //   .data(AstackData)
      //   .join((group: any) => {
      //     const enter = group.append('g').attr('class', 'areasGroupGroup');
      //       enter.append('path').attr('class', 'areaGroupItems averageDot');
      //     return enter;   
      //   })

      // areaGroup.select('.averageDot')
      //   .attr('d', area)
      //   .attr('fill', (d: any) => d.type === 'brush' ? 'none': myChart.props.barFill[myChart.props.barLabels[d.key]])
      //   .style('stroke-width', 1)
      //   .attr('transform', (d: any) => 'translate(' + margins.left + ',' + (d === 'brush' ? margins.mid + margins.sub + chartHeight + margins.top : margins.top) +')');

      // // For brush chart
      // areabrush.select('.averageDot')
      //   .attr('d',(d:any) => areaBrush(d))
      //   .attr('fill', (d: any) => myChart.props.barFill[myChart.props.barLabels[d.key]])
      //   .style('stroke-width', 1)
      //   .attr('transform', (d: any) => 'translate(' + margins.left + ',' + (margins.mid + margins.sub + chartHeight + margins.top ) + ')');


      /////////////////////////////////
      // bar creation starts

      // chartGroup
      //   // .select('.barsGroup')
      //   .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? chartHeight + chartHeight : 0) + ')');
debugger
      const xScaleSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, numberOfSubgroups * (barWidth + barPadding * barWidth)]) // Add space between bars
        .padding(barPadding)
        .paddingOuter(0);

      const xScale: any = d3.scaleBand()
      .domain(xDomain)
      .range([0, width - margins.left - margins.right])
      .padding(barPadding)
      .paddingOuter(1.5);

      const xAxis = d3.scaleBand()
        .domain(subGroup)
        .range([0, xScale.bandwidth()])
        .padding(barPadding)
        .paddingOuter(1.5);

      // Prepare data for stacking by restructuring each subgroup's data
      const stackedBarData = Array.from(groupedData, ([date, values]) => {

        const subgroupData = subgroups.map((source:any) => {
          const entry:any = {};
          entry[myChart.props.subGroupName] = source
          
          kpiNames.forEach((key:any) => {
            const matchingEntry = values.find((v) => v[myChart.props.subGroupName] === source && v[myChart.props.stackVar] === key);
            entry[key] = matchingEntry ? matchingEntry[myChart.props.stackValueVar] : 0;

          });
          entry['date'] = date
          return entry;
        });
        return { date, values: subgroupData };
      });

      // Create the stack generator
      const stack = d3.stack()
        .keys(kpiNames);

      chartGroup
        .select('.barsGroupchart')
        .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? margins.mid + margins.sub + chartHeight : 0) + ')');

      chartGroup
        .select('.barsGroupbrush')
        .attr('transform', (d: any) => 'translate(0, 0)');
        
      // Create the main chart group
      const mainChartGroup = chartGroup
        .select('.barsGroupchart')
        .selectAll('.barsGroupGroupMain')
        .data(stackedBarData)
        .join('g')
        .attr('class', 'barsGroupGroupMain')
        .attr('transform', (d:any) => {
          const subWidth = numberOfSubgroups * (barWidth + barPadding * barWidth);
          return `translate(${xScaleSelected(new Date(d.date)) - subWidth/2}, 0)`; // Center the group
        });

      // // Append bars for each subgroup using stacking
      mainChartGroup
        .selectAll('g.barsStackGroup')
        .data((d:any) => stack(d.values)) // Apply stacking to subgroup data
        .join('g')
        .attr('class', 'barsStackGroup')
        .selectAll('rect')
        .data((d:any) => {
          let value = d.map((value:any) => ({
            ...value,
            [myChart.props.subGroupName]: value.data[myChart.props.subGroupName],
            key: d.key,
            value: value[1] - value[0] // Calculate the actual height
          }));
          return value;
        })
        .join('rect')
        .attr('x', (d:any) => xScaleSubgroup(d[myChart.props.subGroupName])) // Position based on subgroup scale
        .attr('y', (d:any) => chartHeight) // y-position based on the top of the stack
        .attr('height', (d:any) => 0) // Height based on stack height
        .attr('width', Math.abs(barWidth)) // Set bar width
        .attr('fill', (d:any,i:any) =>myChart.props.staticColor ? myChart.props.colors[i] : myChart.props.barFill[d.key])
        .attr('transform', (d: any) => 'translate(' + margins.left + ',' + (d === 'brush' ? margins.mid + margins.sub + chartHeight + margins.top : margins.top) +')')
        
        .on("mousemove", function (event: any, d: any) {
          // get position date by inverting scale
          const positionDate = xScaleSelected.invert(event.offsetX - margins.left);
          // then look at data and get closest date
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(new Date(dateSet[closest - 1]), positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(new Date(dateSet[closest]), positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            closest = dateSet.length - 1;
          }

          // Highlight the hovered bar
          // mySvg.selectAll('.barsStackGroup rect').attr("opacity", .8);
          // mySvg
          //   .selectAll('.barsStackGroup rect')
          //   .filter((barData: any) => barData.data[myChart.props.subGroupName] === d[myChart.props.subGroupName] && barData.data.date === d.data.date)
          //   .attr('opacity', 1)

          if(myChart.props.subgroups==true){
            mySvg.selectAll('.source-label').attr("font-weight", 500);
            mySvg
              .selectAll('.source-label')
              .filter((barData: any) => barData[myChart.props.subGroupName] === d[myChart.props.subGroupName] && barData.date === d.data.date)
              .attr("font-weight", 600);
          }

            
          // show tooltip
          const selectedTooltipData = tooltipData[String(dateSet[closest])][d[myChart.props.subGroupName]]
          
          selectedTooltipData['date'] = formatDate(d.data['date'], timePeriod);
          
          function formatDate(dateString: string, timePeriod: string): string {
            const date = new Date(dateString); // Convert date string to Date object
            const dateFormat = d3.timeFormat(tickFormat[timePeriod]); // Get formatter for the time period
            return dateFormat(date); // Format and return the date
          }
          const filteredData = Object.fromEntries(
            Object.entries(d.data).filter(([key, value]) => value !== 0)
        );
          selectedTooltipData.data=[filteredData]

          // selectedTooltipData['date'] = d3.timeFormat(tickFormat[timePeriod]);
          myChart.showTooltip('clusterStackedBar', selectedTooltipData, event.offsetX, event.offsetY, width, height,barColors);
        })
        .on('mouseout', () => {
          myChart.hideTooltip('clusterStackedBar');
          // mySvg.selectAll('.barsStackGroup rect').attr("opacity", 1);
          mySvg.selectAll('.source-label').attr("font-weight", 500);
        }).on('click', function(event:any, d:any) {
          // get position date by inverting scale
          const positionDate = xScaleSelected.invert(event.offsetX - margins.left);
          // then look at data and get closest date
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(new Date(dateSet[closest - 1]), positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(new Date(dateSet[closest]), positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            closest = dateSet.length - 1;
          }
          const selectedTooltipData = tooltipData[String(dateSet[closest])][d[myChart.props.subGroupName]]
          
          selectedTooltipData['date'] = formatDate(d.data['date'], timePeriod);
          selectedTooltipData['period'] = dateSet[closest];
          function formatDate(dateString: string, timePeriod: string): string {
            const date = new Date(dateString); // Convert date string to Date object
            const dateFormat = d3.timeFormat(tickFormat[timePeriod]); // Get formatter for the time period
            return dateFormat(date); // Format and return the date
          }
          myChart.detailChart(selectedTooltipData,barColors)
          // console.log(d);
          
                  })
        // .transition()
        // .ease(d3.easeCubic)
        // .duration(1000)
        .attr('y', (d: any) => yScale(d[1])) // y-position based on the top of the stack
        .attr('height', (d: any) => yScale(d[0]) - yScale(d[1]))

      // Add labels for each subgroup below the bars// Add text labels for source names
     if( myChart.props.subgroups==true){
      mainChartGroup
        .selectAll('text')
        .data((d: any) => d.values) // Use subgroup data for positioning
        .join('text')
        .attr('class', 'source-label')
        .attr('x', (d: any) => {
          const xSc:any = xScaleSubgroup(d[myChart.props.subGroupName]);
          return xSc + xScaleSubgroup.bandwidth() / 2 
        }) // Center the label
        .attr('y', (d: any) => margins.mid + margins.sub + chartHeight - margins.axis ) // Position slightly above the x-axis
        .attr('text-anchor', 'end') // Center text horizontally
        .text((d: any) => {
          const label = d[myChart.props.subGroupName];
          return subgroups.length > 1 ? label.length > 9 ? `${label.substring(0, 9)}...` : label : '';
        })
        .style('fill', 'black')
        .style('font-family', 'Poppins')
        .attr('cursor', 'default')
        .style('font-size', (barWidth > 10 ? 11 : barWidth + 1) +'px')
        .attr('transform', (d: any) => {
          const xSc:any = xScaleSubgroup(d[myChart.props.subGroupName]);
          return `rotate(-45, ${xSc + xScaleSubgroup.bandwidth() + margins.mid}, ${yScale(0) - 10})`
        });
    }
      // Create the brush group for the brush area
      const brushGroup = chartGroup
        .select('.barsGroupbrush')
        .selectAll('.barsGroupGroupBrush')
        .data(stackedBarData)  // Use the formatted stacked data
        .join((group: any) => {
          const enter = group.append('g')
            .attr('class', 'barsGroupGroupBrush')  // Brush group
            .attr('transform', (d:any) => {
              const balWidth = xAxis.bandwidth() >= barWidth ? (xAxis.bandwidth() - barWidth) : 0;
              return `translate(${xScaleAll(new Date(d.date)) - (xScale.bandwidth()/2) + balWidth}, 0)`; // Center the group
            });
          return enter;
        });


      // Now append bars for the stacked subgroups in the brush area
      brushGroup
        .selectAll('g.brushBarsStackGroup')
        .data((d: any) => stack(d.values))  // Apply stacking to the subgroup data
        .join('g')
        .attr('class', 'brushBarsStackGroup')
        .selectAll('rect')
        .data((d: any) => d.map((value: any) => ({
          ...value,
          [myChart.props.subGroupName]: value.data[myChart.props.subGroupName], 
          key: d.key,  // Key for the stack (e.g., 'New Patients', 'Refill Patients')
          value: value[1] - value[0]  // Calculate the height for the brush bars
        })))
        .join('rect')
        .attr('x', (d: any) => xAxis(d[myChart.props.subGroupName]))  // Use xAxis for positioning subgroups
        .attr('y', (d: any) => yScaleBrush(d[1]))  // y-position based on the top of the stack
        .attr('height', (d: any) => yScaleBrush(d[0]) - yScaleBrush(d[1]))  // Calculate height for stacked brush bars
        .attr('width', Math.max(0, Math.min(barWidth, xAxis.bandwidth())))
        // .attr('width', xAxis.bandwidth() >= barWidth ? barWidth : xAxis.bandwidth() )  // Width for each subgroup
        .attr('fill', (d:any,i:any) =>myChart.props.staticColor ? myChart.props.colors[i] : myChart.props.barFill[d.key])  // Fill color based on the subgroup
        .attr('transform', (d: any) => 'translate(' + margins.left + ',' + (margins.mid + margins.sub + chartHeight + margins.top ) + ')');
        
    }

    function brushed(event: any): void {
      const selection = event.selection;
      
         // Check if the bar's x-coordinate falls within the brushed area.
      if(selection !== null) {

        const maxBrushWidth = (startingVisibleRows / chartData.length) * (width - margins.left - margins.right);

        // If the current brush width exceeds the allowed maximum, adjust the right side while maintaining the left
        const brushWidth = selection[1] - selection[0];
        if (brushWidth > maxBrushWidth) {
          const limitedSelection = [selection[0], selection[0] + maxBrushWidth];
          
          // Check if adjusting right side would go beyond the maximum width of the chart, if so, adjust the left
          if (limitedSelection[1] > width - margins.left - margins.right) {
            limitedSelection[0] = width - margins.left - margins.right - maxBrushWidth;
            limitedSelection[1] = width - margins.left - margins.right;
          }

          mySvg
            .select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, limitedSelection);
          return; // Exit early as the selection has been reset
        }

        // re-style selection box for the brush selection
        mySvg.selectAll('#brushSelectionGroup' + myClass)
        .selectAll('.selection').attr('class', 'selection lineSelectionBox');

        const xDomain: any = selection.map(xScaleAll.invert);
        const dataRemaining: any = chartData.filter((f: any) => f.date >= xDomain[0] && f.date <= xDomain[1]);
        
        if (dataRemaining.length < 1) {
          const previousStart = xScaleAll(xScaleSelected.domain()[0]);
          const previousEnd = xScaleAll(xScaleSelected.domain()[1]);
          mySvg
            .select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, myChart.props.showBrush === false ? null : [previousStart, previousEnd]);
        } else {
          xScaleSelected.domain(xDomain);
          mySvg
            .select('#xAxis' + myClass)
            // @ts-ignore
            .call(
              d3
                .axisBottom(xScaleSelected)
                .tickSizeOuter(0)
                .tickFormat(xTickFormat)
                .ticks(tickCount)
                .tickValues(tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1]))
              )
            .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');

          drawChart();
        }
      }
    }
  
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function (): any {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', 0)
          .attr('dy', dy + 'em');
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          // @ts-ignore
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0) {
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', 0)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word);
            }
          }
        }
      });
      if (lineNumber > 1) {
        text.selectAll('tspan').remove();
        text.attr('id', originalValue);
        text.text(originalValue.substr(0, 5) + '..');
      }
    }

    this.messageEvent.emit(false);
  }
backarrow(){
  this.loader=true
  this.isVisible = true
  this.arrowVisible = false
  setTimeout(() => {
    d3.select('#svg_' + this.divId).remove();
    this.initiateCharts();
    this.plotChart();
    this.loader=false
  }, 200);
 
}
}
